import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Hakkımızda | Discover Vitality Vault
			</title>
			<meta name={"description"} content={"Her Yolculuğun Güçle Başladığı Yer"} />
			<meta property={"og:title"} content={"Hakkımızda | Discover Vitality Vault"} />
			<meta property={"og:description"} content={"Her Yolculuğun Güçle Başladığı Yer"} />
			<meta property={"og:image"} content={"https://mobizaplay.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://mobizaplay.com/img/2964514.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://mobizaplay.com/img/2964514.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://mobizaplay.com/img/2964514.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://mobizaplay.com/img/2964514.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://mobizaplay.com/img/2964514.png"} />
			<meta name={"msapplication-TileImage"} content={"https://mobizaplay.com/img/2964514.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-width="100%"
				>
					Hikayemiz Açılıyor
				</Text>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					lg-width="100%"
				>
					Sıfırdan tek bir vizyonla yaratılmış bir sığınak olan Vitality Vault'un özünü keşfedin: sağlık ve zindeliğe adanmış bir topluluğa ilham vermek, motive etmek ve geliştirmek. Mütevazı başlangıcımızdan bu yana, her üyenin gücünü keşfedebileceği, sınırlarını zorlayabileceği ve bir zamanlar ulaşılamaz görünen şeyleri başarabileceği bir alan yaratmaya çalıştık. Temelimiz, fitness'ın fiziksel sınırları aştığı, hem zihni hem de ruhu beslediği inancı üzerine inşa edilmiştir.
				</Text>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-width="100%"
				>
					Eğitmenlerimizle Tanışın
				</Text>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					lg-width="100%"
				>
					Vitality Vault'un kalbinde, fitness tutkusu sadece sizin başarınıza olan bağlılıklarıyla eşleşen, kendini işine adamış profesyonellerden oluşan bir ekip olan eğitmenlerimiz yer almaktadır. Farklı geçmişlere sahip eğitmenlerimiz, yolculuğunuzun sadece etkili değil aynı zamanda keyifli olmasını sağlamak için zengin bilgi birikimi, benzersiz teknikler ve motivasyon stratejileri sunar. Ekibimizin her bir üyesi size rehberlik etmek, destek olmak ve ilham vermek için burada, Vitality Vault'taki her seansı en iyi halinize doğru bir adım haline getiriyor.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://mobizaplay.com/img/5.jpg"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
				/>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://mobizaplay.com/img/6.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 0px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Neden Öne Çıkıyoruz
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Yenilikçi Programlar: Son teknoloji fitness derslerinden kişiselleştirilmiş antrenman seanslarına kadar, yenilikler tekliflerimizi yönlendirir.
					<br />
					<br />
					Topluluk Etkinlikleri: Üyeleri bir araya getiren düzenli etkinlikler, spor salonunun ötesine geçen bağlar yaratır.
					<br />
					<br />
					Bütünsel Sağlık: Beslenme, farkındalık ve genel esenlik üzerine atölye çalışmaları sunarak bütünsel bir yaklaşımı benimsiyoruz.
					<br />
					<br />
					Çevre Dostu Alan: Sürdürülebilirlik, çevre dostu malzemeler ve uygulamalarla tasarımımızın temelini oluşturmaktadır.
				</Text>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://mobizaplay.com/img/7.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-4">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					width="40%"
					font="normal 500 52px/1.2 --fontFamily-serifTimes"
					padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					md-padding="0px 0 0px 0px"
					md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
					sm-margin="0px 0px 35px 0px"
				>
					Hikayemize Katılın
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" color="#727a82" sm-margin="0px 0px 25px 0px">
						Vitality Vault ile bir dönüşüm yolculuğuna çıkın. Burada hikayeniz bizimkiyle iç içe geçerek bir ilerleme, dayanıklılık ve topluluk anlatısı yaratır. Kişisel zafere doğru yol alırken, sadece bir spor salonuna katılmadığınızı, her kilometre taşına, her damla tere ve ulaşılan her hedefe değer veren bir hareketin önemli bir parçası haline geldiğinizi unutmayın.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});